import React, { useState } from "react";
import logo from "../images/logo.jpg";
import qrcode from "../images/qrcode.png";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { HiOutlineUserCircle } from "react-icons/hi";
import { FaXTwitter } from "react-icons/fa6";
import { BsFacebook } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";

const TermsAndConditions = () => {
  const [showNavigation, setShowNavigation] = useState(false);

  return (
    <section class="w-full">
      <div className="W-full flex flex-col">
        <div className="hidden md:flex gap-3 px-4 items-center justify-end py-1">
          <p className="flex gap items-center justify-center text-green-500">
            <BsTelephone />
            <span className="text-primary text-sm">+254786737737</span>
          </p>

          <p className="flex items-center justify-center text-green-500">
            <AiOutlineMail />
            <a
              href="mailto:info@dialaserviceke.com"
              className="text-primary text-sm"
            >
              info@dialaserviceke.com
            </a>
          </p>

          <div className="flex gap-1 items-center justify-center text-green-500 bg-primary p-1 rounded-md cursor-pointer">
            <span className="bg-white p-1 text-xl rounded-full">
              <HiOutlineUserCircle />
            </span>
            <a className="text-white text-sm font-bold">
              {" "}
              Request For Service{" "}
            </a>
          </div>
        </div>
        <div className={`flex-1 md:p-2 backgroundImage relative`}>
          <nav className="flex items-center justify-between px-4 md:px-10 bg-primary md:bg-transparent z-40 py-1 fixed w-screen md:w-full md:static">
            <div className="flex items-center gap-5">
              <img
                src={logo}
                alt="logo"
                className="h-12 md:h-16 border rounded-full border-primary shadow-md shadow-primary"
              />
              <p className="hidden md:blcok capitalize text-xl text-white font-bold text-shadow">
                Dial A Service
              </p>
            </div>
            <div
              onClick={() => setShowNavigation(true)}
              className="h-full w-6 flex flex-col gap-1 cursor-pointer md:hidden"
            >
              <div className="bg-white h-1 w-full"></div>
              <div className="bg-white h-1 w-full"></div>
              <div className="bg-white h-1 w-full"></div>
            </div>
            <div
              className={`${
                showNavigation ? "flex absolute" : "hidden"
              } top-0 left-0 text-black w-screen h-screen md:h-max md:flex flex-col bg-white md:bg-transparent md:static md:flex-row font-xl md:w-3/4 items-center justify-evenly md:text-white font-bold`}
            >
              <div
                onClick={() => setShowNavigation(false)}
                className="absolute top-10 right-10 h-8 w-6 flex flex-col gap-1 cursor-pointer md:hidden"
              >
                <div className="bg-red-500 h-[2px] w-full rotate-45 absolute"></div>
                <div className="bg-red-500 h-[2px] w-full -rotate-45 absolute"></div>
              </div>

              <a
                href="/"
                onClick={() => setShowNavigation(false)}
                className="text-primary md:text-white"
              >
                Home
              </a>
              <a
                href="/"
                onClick={() => setShowNavigation(false)}
                className="text-primary md:text-white"
              >
                Our Services
              </a>
              <a
                href="/"
                onClick={() => setShowNavigation(false)}
                className="text-primary md:text-white"
              >
                FAQ
              </a>
              <div className="md:hidden flex gap-1 items-center justify-center text-green-500 bg-primary p-1 rounded-md cursor-pointer">
                <span className="bg-white p-1 text-xl rounded-full">
                  <HiOutlineUserCircle />
                </span>
                <a className="text-white text-sm font-bold">
                  {" "}
                  Request For Service{" "}
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="p-2 text-sm mt-12 md:mt-0">
        <h1 className="text-primary font-bold text-center py-2">
          Terms and Conditions for Dial a Service Customers
        </h1>

        <ol className="text-sm list-decimal px-5 py-2">
          <li className="px-1 text-primary">Payment:</li>
          <p>
            Payment for services is required upfront at the time of booking. In
            cases where the service cannot be provided by Dial a Service, the
            full payment amount will be refunded to the customer.
          </p>
          <li className="px-1 text-primary">Cancellation Policy:</li>
          <p>
            Cancellation of a service more than 24 hours before the scheduled
            task will result in the deduction of only transaction charges, and
            the remaining amount will be refunded to the customer. Cancellation
            of a service less than 24 hours before the scheduled task will
            result in a 20% deduction from the total service cost, and the
            remaining amount will be refunded to the customer.
          </p>

          <li className="px-1 text-primary">Rescheduling:</li>
          <p>
            Customers may request to reschedule a service up to 24 hours before
            the scheduled task without incurring any additional charges.
            Rescheduling requests made less than 24 hours before the scheduled
            task may be subject to approval and additional charges.
          </p>

          <li className="px-1 text-primary">Service Provider Allocation:</li>
          <p>
            Dial a Service reserves the right to allocate service providers
            based on availability and customer requirements. Every effort will
            be made to match customer preferences, but it is not guaranteed.
          </p>

          <li className="px-1 text-primary">Service Quality:</li>
          <p>
            Dial a Service is committed to providing high-quality services. If a
            customer is unsatisfied with the service provided, they should
            contact customer support within 24 hours of the task for resolution.
          </p>

          <li className="px-1 text-primary">Liability:</li>
          <p>
            Dial a Service and its service providers will take all reasonable
            precautions to ensure customer safety and satisfaction. Dial a
            Service is not liable for any damages, losses, or injuries that
            occur during or as a result of the service provided.
          </p>

          <li className="px-1 text-primary">Privacy and Data Protection:</li>
          <div className="">
            <p>
              We use the information we collect, including phone number and
              email to provide a personalized experience to you, including ads,
              along with the other purposes we explain in detail below.
            </p>

            <ul className="list-disc px-10 pb-2">
              <li>
                We utilize the information we collect to safeguard individuals
                from harm and offer secure and protected services
              </li>
              <li>
                For measurement, analytics, and business services This assists
                in gauging the effectiveness of our ads, content, products, and
                services.
              </li>
              <li>For communication and marketing purposes:</li>
              <p className="px-3">
                We engage with you using the information you've provided, such
                as the contact details entered in your profile. The
                communications include but are not limited to: Marketing and
                promotional messages, Order updates and alerts, verification
                codes and handling service inquiries. The following channels
                will be utilized; WhatsApp Business messaging, Emails and direct
                SMS.
              </p>
            </ul>

            <p></p>
          </div>

          <p>
            Customer information and data will be protected in accordance with
            applicable data protection laws and Dial a Service's{" "}
            <a href="/privacy" className="text-blue-800 underline">
              Privacy Policy.
            </a>
          </p>

          <li className="px-1 text-primary">Code of Conduct:</li>
          <p>
            Customers are expected to treat service providers with respect and
            professionalism. Any form of harassment or inappropriate behavior
            towards service providers will not be tolerated.
          </p>

          <li className="px-1 text-primary">Feedback and Complaints:</li>
          <p>
            Customers are encouraged to provide feedback on services and report
            any issues or complaints to Dial a Service's customer support team
            for resolution.
          </p>

          <li className="px-1 text-primary">
            Service Provider Damage and Refund Policy:
          </li>
          <p>
            In the event that a service provider causes damage to a customer's
            property during the service, Dial a Service/Partner will investigate
            the incident. Depending on the severity and nature of the damage,
            the partner compensate the customer for the repair or replacement
            costs. Customers should report damages promptly to Dial a Service's
            customer support team for assessment and resolution.
          </p>

          <li className="px-1 text-primary">Service Provider Injury:</li>
          <p>
            Service providers are responsible for their own safety while
            performing services for Dial a Service. In the event that a service
            provider sustains an injury while on duty, Dial a Service/partner
            may provide assistance in seeking medical attention as necessary.
            Dial a Service is not liable for any injuries sustained by service
            providers during service provision.
          </p>

          <li className="px-1 text-primary">Sexual Harassment Policy:</li>
          <p>
            Dial a Service maintains a strict policy against sexual harassment
            in any form. Any reports of sexual harassment by either customers or
            partners will be thoroughly investigated. Violations of the sexual
            harassment policy may result in immediate suspension or termination
            of the offending party's access to Dial a Service.
          </p>

          <li className="px-1 text-primary">
            Client Unavailability at Task Completion and Task Duration:
          </li>
          <p>
            In the event that a task is completed, and the client is not present
            at the task location at the time of completion, the service provider
            shall make reasonable attempts to contact the client using the
            contact information provided. If the client does not respond within
            a specified period (not exceeding 30 minutes), the service provider
            may leave the task location and consider the task as successfully
            completed. Any further instructions or concerns from the client
            regarding the completed task shall be communicated through Dial a
            Service's customer support. This addition ensures that if the client
            does not formally end the task within the specified duration (in
            this case, not exceeding 30 minutes), the system will automatically
            consider the task as completed, allowing for efficient task
            management and service provider scheduling.
          </p>

          <li className="px-1 text-primary">
            Client Unavailability or Unreachability:
          </li>
          <p>
            In the event that a client is unavailable or unreachable at the
            scheduled service time, Dial a Service team/Partner/Service provider
            will attempt to contact the requestor using the provided contact
            information. If the client cannot be reached within a reasonable
            timeframe (e.g., 30 minutes), and if the service provider is on-site
            and ready to perform the task, Dial a Service/Partner may proceed
            with the service. The service fee will not be refunded to the client
            in cases of unavailability or unreachability once the service has
            commenced.
          </p>

          <li className="px-1 text-primary">Force Majeure:</li>
          <p>
            Dial a Service should not be held liable for any delays or failure
            in performance caused by circumstances beyond its control, such as
            natural disasters, acts of terrorism, or government actions
          </p>

          <li className="px-1 text-primary">Indemnification:</li>
          <p>
            Customers should indemnify Dial a Service, its partners, and service
            providers against any claims, liabilities, losses, or expenses
            resulting from their use of the platform or services.
          </p>

          <li className="px-1 text-primary">Dispute Resolution:</li>
          <p>
            In the event of any dispute or disagreement between a customer and
            Dial a Service, or between a customer and a partner, both parties
            agree to first attempt to resolve the matter amicably through
            mediation or arbitration, as outlined in the following steps:
            Mediation: If a dispute arises, the parties involved shall make a
            good-faith effort to resolve the dispute through mediation. A
            mutually agreed-upon mediator will facilitate the mediation process.
            Both parties shall share the costs of mediation equally.
            Arbitration: If mediation does not result in a satisfactory
            resolution, the dispute shall be submitted to binding arbitration.
            The arbitration proceedings will be conducted in accordance with the
            rules and procedures of a recognized arbitration organization agreed
            upon by both parties. Binding Decision: The decision of the
            arbitrator(s) will be final and binding on both parties, and any
            court of competent jurisdiction may enforce it. Each party shall
            bear their own legal expenses and the costs of arbitration. This
            dispute resolution process is designed to provide an efficient and
            cost-effective means of resolving disputes and is a mandatory
            prerequisite before initiating any legal action.
          </p>

          <li className="px-1 text-primary">
            Amendments to Terms and Conditions:
          </li>
          <p>
            Dial a Service reserves the right to amend these Terms and
            Conditions at any time. Customers will be notified of any changes.
          </p>
        </ol>
      </div>

      <div className="flex flex-col p-5">
        <h1 className="font-bold text-xl py-3 text-primary">
          It's Easier On The App
        </h1>
        <div className="flex-1 border border-primary rounded-md p-5 flex gap-5 items-center">
          <img src={qrcode} alt="qrcode" className="h-24 md:h-52" />
          <div>
            <p className="text-sm md:text-xl font-bold p-2">
              Download the Dial A Service App
            </p>
            <p className="text-primary font-bold p-2 flex items-center justify-between">
              scan to download{" "}
              <span className="text-black text-2xl font-bold">
                <BsArrowRight />
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="text-white text-sm bg-black flex flex-wrap items-start justify-evenly p-5 pb-10 relative">
        <div className="flex flex-col">
          <h1 className="text-primary font-bold py-1">Social Media</h1>
          <div className="flex justify-center gap-2">
            <a
              href="https://x.com/DialAService01?t=x4Pc-_t4f54X0UQMeSkqrQ&s=09"
              target="_blank"
            >
              <FaXTwitter />
            </a>
            <a
              href="https://www.facebook.com/dailaservice?mibextid=ZbWKwL"
              target="_blank"
            >
              <BsFacebook />
            </a>
          </div>
        </div>
        <div className="">
          <h1 className="text-primary font-bold py-1">Company</h1>
          <div>
            <a className="block" href="/about">
              About Us
            </a>
            <a className="block" href="/termsandconditions">
              Terms and Conditions
            </a>
            <a className="block" href="/privacy">
              Privacy Policies
            </a>
          </div>
        </div>
        <div className="">
          <h1 className="text-primary font-bold py-1">Our Services</h1>
          <div>
            <p>Home Services</p>
            <p>Proffesional Services</p>
            <p>Convenience Services</p>
            <p>Events and special occasions</p>
          </div>
        </div>
        <div className="">
          <h1 className="text-primary font-bold py-1">Contact Us</h1>
          <div>
            <a href="tel:+254716009182">+254 786737737</a>
          </div>
        </div>

        <p className="absolute bottom-0 left-0 right-0 text-center m-auto">
          &copy; {new Date().getFullYear()} Zeaworld Ltd. All rights reserved.
        </p>
      </div>
    </section>
  );
};

export default TermsAndConditions;
