import React, { useState } from "react";
import logo from "../images/logo.jpg";
import qrcode from "../images/qrcode.png";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { HiOutlineUserCircle } from "react-icons/hi";
import { FaXTwitter } from "react-icons/fa6";
import { BsFacebook } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";

const Privacy = () => {
  const [showNavigation, setShowNavigation] = useState(false);

  return (
    <section class="w-full">
      <div className="W-full flex flex-col">
        <div className="hidden md:flex gap-3 px-4 items-center justify-end py-1">
          <p className="flex gap items-center justify-center text-green-500">
            <BsTelephone />
            <span className="text-primary text-sm">+254786737737</span>
          </p>

          <p className="flex items-center justify-center text-green-500">
            <AiOutlineMail />
            <a
              href="mailto:info@dialaserviceke.com"
              className="text-primary text-sm"
            >
              info@dialaserviceke.com
            </a>
          </p>

          <div className="flex gap-1 items-center justify-center text-green-500 bg-primary p-1 rounded-md cursor-pointer">
            <span className="bg-white p-1 text-xl rounded-full">
              <HiOutlineUserCircle />
            </span>
            <a className="text-white text-sm font-bold">
              {" "}
              Request For Service{" "}
            </a>
          </div>
        </div>
        <div className={`flex-1 md:p-2 backgroundImage relative`}>
          <nav className="flex items-center justify-between px-4 md:px-10 bg-primary md:bg-transparent z-40 py-1 fixed w-screen md:w-full md:static">
            <div className="flex items-center gap-5">
              <img
                src={logo}
                alt="logo"
                className="h-12 md:h-16 border rounded-full border-primary shadow-md shadow-primary"
              />
              <p className="hidden md:blcok capitalize text-xl text-white font-bold text-shadow">
                Dial A Service
              </p>
            </div>
            <div
              onClick={() => setShowNavigation(true)}
              className="h-full w-6 flex flex-col gap-1 cursor-pointer md:hidden"
            >
              <div className="bg-white h-1 w-full"></div>
              <div className="bg-white h-1 w-full"></div>
              <div className="bg-white h-1 w-full"></div>
            </div>
            <div
              className={`${
                showNavigation ? "flex absolute" : "hidden"
              } top-0 left-0 text-black w-screen h-screen md:h-max md:flex flex-col bg-white md:bg-transparent md:static md:flex-row font-xl md:w-3/4 items-center justify-evenly md:text-white font-bold`}
            >
              <div
                onClick={() => setShowNavigation(false)}
                className="absolute top-10 right-10 h-8 w-6 flex flex-col gap-1 cursor-pointer md:hidden"
              >
                <div className="bg-red-500 h-[2px] w-full rotate-45 absolute"></div>
                <div className="bg-red-500 h-[2px] w-full -rotate-45 absolute"></div>
              </div>

              <a
                href="/"
                onClick={() => setShowNavigation(false)}
                className="text-primary md:text-white"
              >
                Home
              </a>
              <a
                href="/"
                onClick={() => setShowNavigation(false)}
                className="text-primary md:text-white"
              >
                Our Services
              </a>
              <a
                href="/"
                onClick={() => setShowNavigation(false)}
                className="text-primary md:text-white"
              >
                FAQ
              </a>
              <div className="md:hidden flex gap-1 items-center justify-center text-green-500 bg-primary p-1 rounded-md cursor-pointer">
                <span className="bg-white p-1 text-xl rounded-full">
                  <HiOutlineUserCircle />
                </span>
                <a className="text-white text-sm font-bold">
                  {" "}
                  Request For Service{" "}
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="p-2 text-sm mt-12 md:mt-0">
        <h1 className="text-primary font-bold text-center py-2">
          DATA PRIVACY & PROTECTION POLICY – ZEA WORLD LIMITED
        </h1>

        <h2 className="text-xl font-bold text-primary underline">
          Introduction
        </h2>
        <p className="py-1">
          As part of its daily operations, Zea World Limited (“Zea World”) under
          our brand “Dial a service” will collect and process certain types of
          information of individuals that make them easily identifiable. These
          individuals include customers, current, past and prospective employees
          of Zea world, service providers , Employees of service providers, and
          other individuals whom Zea World communicates or deals with, jointly
          and/or severally.
        </p>
        <p className="py-1">
          This privacy policy outlines the data we collect, how we use it, and
          the choices you have regarding your information while using your
          mobile application. Maintaining the Data Subject’s trust and
          confidence requires that Data Subjects do not suffer negative
          consequences/effects as a result of providing Zea World with their
          Personal Data. To this end, Zea World is firmly committed to complying
          with applicable data protection laws, regulations, rules and
          principles to ensure security of Personal Data handled by the Company
        </p>
        <p className="py-1">
          This Policy applies to all forms of systems, operations and processes
          within the Zea World environment that involve the collection, storage,
          use, transmission and disposal of Personal Data
        </p>

        <h2 className="text-xl font-bold text-primary underline">Scope</h2>
        <p className="py-1">
          This Policy applies to all customers’ and employees of Zea world, as
          well as to any external business partners (such as service providers,
          suppliers, contractors, vendors and other service providers) who
          receive, send, collect, access, or process Personal Data in any way on
          behalf of Zea World, including processing wholly or partly by
          automated means. This Policy also applies to third party Data
          Processors who process Personal Data received from Zea World
        </p>

        <h2 className="text-xl font-bold text-primary underline">
          Information We Collect
        </h2>
        <p className="py-1 italic">
          {" "}
          To provide you with the best user experience and functionalities of
          our App, we may collect the following classes of data
        </p>

        <div className="px-2">
          <h2 className="font-bold text-primary">Location</h2>
          <p className="py-1">
            Approximate Location: We may collect your approximate location, such
            as city or region, to personalize your experience and offer
            location-based features.
          </p>
          <p className="py-1">
            Precise Location: we may also collect your precise GPS-based
            location to provide you with location - specific services
          </p>

          <h2 className="font-bold text-primary">Personal information.</h2>
          <p>
            {" "}
            <span className="font-bold italic">Name: </span> We collect your
            full names to personalize your interactions with the App
          </p>
          <p>
            {" "}
            <span className="font-bold italic">Email: </span> your email address
            is used for communication purposes, such as account verification,
            updates, and customer support.
          </p>
          <p>
            {" "}
            <span className="font-bold italic">User ID: </span> We may assign a
            unique identifier to your account to facilitate functionality and
            personalization.
          </p>
          <p>
            {" "}
            <span className="font-bold italic">Address: </span> We collect your
            address for effective service provision especially when dispatching
            service providers to your specified address.
          </p>
          <p>
            {" "}
            <span className="font-bold italic">Phone Number: </span> We collect
            your phone number to enable certain features and for account
            verification as well as for communication purposes after request
            placement, before service, during service and after service
          </p>
          <p>
            {" "}
            <span className="font-bold italic">Other Info: </span> Any
            additional information you provide, such as preferences or
            interests, help us improve our services and customize your
            experience.
          </p>

          <h2 className="font-bold text-primary">Financial Information</h2>
          <p>
            <span className="font-bold italic">User Payment Info: </span> if you
            make a request through our Application, we collect payment details
            necessary for completing the transactions securely after which the
            service provider for the specific requested service will be
            dispatched.
          </p>
          <p>
            <span className="font-bold italic">Payment History: </span> We
            retain a record of your payment history to assist with customers
            support and to provide you with a better service experience as well
            as offers which may be offered from time to time at the discretion
            of Zea World
          </p>
          <p>
            <span className="font-bold italic">Other financial info: </span> Any
            other financial information provided by you for specific
            transactions or services within the App
          </p>
          <h2 className="font-bold text-primary">Photos and videos</h2>
          <p>
            With your consent, the app may access and store photos and or videos
            from your device to enable certain features or functionalities
            including but not limited to identification.
          </p>
          <h2 className="font-bold text-primary">Files and Docs</h2>
          <p>
            With your permission, the App my access and store files and
            documents from your device to facilitate specific App functions.
          </p>
          <h2 className="font-bold text-primary">Contacts</h2>
          <p>
            If you choose to use features that require access to your contacts,
            we may collect and store contact information.
          </p>
          <h2 className="font-bold text-primary">App info and performance</h2>
          <p>
            <span className="font-bold italic">Crash Logs: </span> The App may
            collect crash logs in the event of a technical issue to improve the
            App performance and resolve problems efficiently.
          </p>
          <h2 className="font-bold text-primary">Device or Other IDs</h2>
          <p>
            <span className="font-bold italic">Device ID: </span> We may collect
            your devices unique identifier to trouble shoot issues and enhance
            user experience.
          </p>
        </div>
        <h2 className="text-xl font-bold text-primary underline">
          How we use your Information
        </h2>
        <p className="py-1">
          We use the data collected for the following purposes:
        </p>
        <ol className="text-sm list-decimal px-5 py-2">
          <li>
            To provide and improve our services, features and user experience of
            the App
          </li>
          <li>
            To personalize your interactions and tailor content based on your
            preferences
          </li>
          <li>
            To process transactions and provide customer support related to your
            service requests.
          </li>
          <li>
            To communicate with you regarding updates, promotions and important
            information about the App. These communications include but are not limited to: Marketing and promotional messages,
Order updates and alerts, verification codes and handling service inquiries. The following channels will be utilized; <span className="font-bold">WhatsApp Business</span>, <span className="font-bold">Emails</span> and <span className="font-bold">Direct SMS</span>
          </li>
          <li>
            To respond to your inquiries and address your feedback or concerns
          </li>
          <li>
            To analyze and monitor App usage, perform data analytics and conduct
            research to enhance our services.
          </li>
          <li>
            To ensure the security and integrity of the App, as well as to
            prevent fraud and unauthorized access
          </li>
          <li>
            Customer service messages. We will use your personal data to keep
            you updated with the latest information or changes about our
            products and services.
          </li>
          <li>
            For marketing purposes. In order to serve you better,we will use your
            personal data to market our products and services to you. The following channels will be utilized; 
            <ul className="px-20 list-disc">
              <li>WhatsApp Business messaging</li>
              <li>Emails</li>
              <li>Direct SMS</li>
            </ul>
          </li>
        </ol>

        <h2 className="text-xl font-bold text-primary underline">
          Data sharing and Disclosure
        </h2>
        <p>
          We do not sell, trade or rent your personal information to third
          parties for marketing purposes. However, we may share your data under
          the following circumstances. 
        </p>

        <ol className="text-sm list-decimal px-5 py-2">
          <li>
            With service providers, business partners, and contractors who help
            us with App operations and services (e.g. Payment processors, cloud
            storage providers) We share information about you with service providers for various purposes, including:
            <ul className="px-20 pb-2 list-disc">
              <li>Facilitating payments</li>
              <li>Providing customer support</li>
              <li>Enhancing the functionality of our services</li>
              <li>Delivering technical infrastructure services</li>
              <li>Analyzing the usage patterns of our services</li>
              <li>Conducting research and surveys</li>
              <li>Marketing and promoting our Products</li>
            </ul>
          </li>
          <li>
            When required by law or legal process, or to protect our rights,
            privacy, safety, or property, or that of others.
          </li>
          <li>
            With your consent or as otherwise disclosed at the time of data
            collection.
          </li>
          <li>
            A merging or acquiring entity where we undergo business
            reorganization e.g. merger, acquisition or takeover.
          </li>
        </ol>

        <h2 className="text-xl font-bold text-primary underline">
          Data Security
        </h2>
        <p>
          We employ reasonable administrative, technical and physical security
          measures to protect your information from unauthorized access,
          disclosure, alteration, or destruction.
        </p>
        <p>
          However, no method of transmission over the internet or electronic
          storage is completely secure. Therefore we cannot guarantee absolute
          security.
        </p>

        <h2 className="text-xl font-bold text-primary underline">
          Your choices and controls
        </h2>
        <p>You have the following rights and controls over your data</p>
        <ol className="text-sm list-decimal px-5 py-2">
          <li>
            You can review and update your personal information within the Apps
            settings or by contacting us directly.
          </li>
          <li>
            You may opt-out of receiving promotional communications from us by
            following the unsubscribe instructions provided in the communication
            or by adjusting your notification preferences.
          </li>
          <li>
            You can disable access to specific data types (e.g. location,
            contacts) through your device settings, however, this may impact
            certain App features and service delivery
          </li>
          <li>
            You may request the deletion of your account and associated data by
            contacting us at{" "}
            <a
              href="mailto:compliance@dialaservice.com"
              className="text-blue-500"
            >
              compliance@dialaservceke.com
            </a>
          </li>
        </ol>

        <h2 className="text-xl font-bold text-primary underline">
          Childs Privacy
        </h2>
        <p>
          The App is not intended for children under the age of 18 years. We do
          not knowingly collect or maintain personal information from
          individuals under this age. If you believe that we have inadvertently
          collected personal information from a child , please contact us
          immediately and we will take steps to delete the information promptly.
        </p>
        <h2 className="text-xl font-bold text-primary underline">
          Changes to this privacy policy{" "}
        </h2>
        <p>
          We may update this privacy policy from time to time to reflect changes
          in our data practices or applicable laws, we encourage you to review
          this page periodically for any updates. We will notify you of
          significant changes by posting a prominent notice in the APP or
          sending you a direct communication through the provided channels.
        </p>
        <h2 className="text-xl font-bold text-primary underline">Contact us</h2>

        <p>
          If you have any questions, concerns or requests regarding this privacy
          policy or our data practices, please contact us at{" "}
          <a
            href="mailto:compliance@dialaservice.com"
            className="text-blue-500"
          >
            compliance@dialaservceke.com
          </a>
        </p>

        <p className="py-2">
          Thank you for using Dial a service App. We value your trust and strive
          to protect your privacy and data security.
        </p>
      </div>

      <div className="flex flex-col p-5">
        <h1 className="font-bold text-xl py-3 text-primary">
          It's Easier On The App
        </h1>
        <div className="flex-1 border border-primary rounded-md p-5 flex gap-5 items-center">
          <img src={qrcode} alt="qrcode" className="h-24 md:h-52" />
          <div>
            <p className="text-sm md:text-xl font-bold p-2">
              Download the Dial A Service App
            </p>
            <p className="text-primary font-bold p-2 flex items-center justify-between">
              scan to download{" "}
              <span className="text-black text-2xl font-bold">
                <BsArrowRight />
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="text-white text-sm bg-black flex flex-wrap items-start justify-evenly p-5 pb-10 relative">
        <div className="flex flex-col">
          <h1 className="text-primary font-bold py-1">Social Media</h1>
          <div className="flex justify-center gap-2">
            <a
              href="https://x.com/DialAService01?t=x4Pc-_t4f54X0UQMeSkqrQ&s=09"
              target="_blank"
            >
              <FaXTwitter />
            </a>
            <a
              href="https://www.facebook.com/dailaservice?mibextid=ZbWKwL"
              target="_blank"
            >
              <BsFacebook />
            </a>
          </div>
        </div>
        <div className="">
          <h1 className="text-primary font-bold py-1">Company</h1>
          <div>
            <a className="block" href="/about">About Us</a>
            <a className="block" href="/termsandconditions">Terms and Conditions</a>
            <a className="block" href="/privacy">Privacy Policies</a>
          </div>
        </div>
        <div className="">
          <h1 className="text-primary font-bold py-1">Our Services</h1>
          <div>
            <p>Home Services</p>
            <p>Proffesional Services</p>
            <p>Convenience Services</p>
            <p>Events and special occasions</p>
          </div>
        </div>
        <div className="">
          <h1 className="text-primary font-bold py-1">Contact Us</h1>
          <div>
            <p>+254 786737737</p>
          </div>
        </div>

        <p className="absolute bottom-0 left-0 right-0 text-center m-auto">
          &copy; {new Date().getFullYear()} Zeaworld Ltd. All rights reserved.
        </p>
      </div>
    </section>
  );
};

export default Privacy;
