import React from "react";
import logo from "../../images/logo.jpg";
import url from "../../baseUrl"

const Provider = () => {
  return (
    <div class=" bg-gray-900">
      <div class="max-w-2xl mx-auto text-white py-10">
        <div class="text-center">
          <h3 class="text-3xl mb-3">
            {" "}
            Download Our Dial A Service Provider App{" "}
          </h3>
          <p> Making Life Easier </p>
          <div class="flex justify-center my-10">
            <div class="flex items-center border w-auto rounded-lg px-4 py-2 mx-2">
              <a
                href={`${url.mainUrl}/admin/app/download?appType=provider`}
                className="flex flex-col items-center justify-center gap-2"
                download
              >
                <img src={logo} class="w-7 md:w-8 rounded-full" />
                <div class="text-left ml-3">
                  <p class="text-xs text-gray-200">Download Now </p>
                  {/* <p class="text-sm md:text-base"> Google Play Store </p> */}
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="mt-28 flex flex-col md:flex-row md:justify-between items-center text-sm text-gray-400">
          <p class="order-2 md:order-1 mt-8 md:mt-0">
            {" "}
            &copy; Zea World, {new Date().getFullYear()}{" "}
          </p>
          <div class="order-1 md:order-2">
            <a href="/about" class="px-2">About us</a>
            <a href="/" class="px-2 border-l">Contact us</a>
            <a href="/privacy" class="px-2 border-l">Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Provider;
