import React from "react";
import logo from "../../images/logo.jpg";
import url from "../../baseUrl"

const Provider = () => {
  return (
    <div class=" bg-gray-900 h-screen relative">
      <div class="max-w-2xl h-full mx-auto text-white py-10 flex flex-col items-center justify-between">
        <div class="text-center">
          <h3 class="text-3xl mb-3">
            {" "}
            Download Our Dial A Service Requestor App
          </h3>
          <p> Making Life Easy </p>
          <div class="flex justify-center my-10">
            <a href="https://play.google.com/store/apps/details?id=com.dial.service.app" target="_blank" class="flex items-center border w-auto rounded-lg px-4 py-2 w-52 mx-2">
                <img src="https://cdn-icons-png.flaticon.com/512/888/888857.png" class="w-7 md:w-8" />
                <div class="text-left ml-3">
                    <p class='text-xs text-gray-200'>Download on </p>
                    <p class="text-sm md:text-base"> Google Play Store </p>
                </div>
            </a>
          </div>
        </div>
        <div class="mt-28 flex flex-col md:flex-row md:justify-between items-center text-sm text-gray-400">
          <p class="order-2 md:order-1 mt-8 md:mt-0">
            {" "}
            &copy; Zea World, {new Date().getFullYear()}{" "}
          </p>
          <div class="order-1 md:order-2">
            <a href="/about" class="px-2">About us</a>
            <a href="/" class="px-2 border-l">Contact us</a>
            <a href="/privacy" class="px-2 border-l">Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Provider;
