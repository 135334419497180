import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "./components/LandingPage";
import AboutUs from "./components/AboutUs";
import Provider from "./components/downloads/Provider";
import Requester from "./components/downloads/Requester";
import NotFound from "./components/NotFound";
import TermsAndConditions from "./components/TermsAndConditions";
import Privacy from "./components/Privacy";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />}/>
        <Route path="/about" element={<AboutUs />}/>
        <Route path="/termsandconditions" element={<TermsAndConditions />}/>
        <Route path="/privacy" element={<Privacy />}/>
        <Route path="/downloads/requester" element={<Requester />}/>
        <Route path="/downloads/provider" element={<Provider />}/>
        <Route path="*" element={<NotFound />}/>
      </Routes>
    </>
  );
}

export default App;
