import React, { useState } from "react";
import logo from "../images/logo.jpg";
import cleaner from "../images/cleaning-worker-with-bucket-and-broom-vector.jpg"
import event from "../images/event.jpg"
import convenience from "../images/convenience.png"
import security from "../images/security.jpeg"
import qrcode from "../images/qrcode.png";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { HiOutlineUserCircle } from "react-icons/hi";
import { FaXTwitter } from "react-icons/fa6"
import { BsFacebook } from "react-icons/bs"
import { BsArrowRight } from "react-icons/bs"

const AboutUs = () => {
    const [showNavigation, setShowNavigation] = useState(false)

  return (
    <section class="w-full">
    <div className="W-full flex flex-col">
      <div className="hidden md:flex gap-3 px-4 items-center justify-end py-1">
        <p className="flex gap items-center justify-center text-green-500">
          <BsTelephone />
          <span className="text-primary text-sm">+254786737737</span>
        </p>

        <p className="flex items-center justify-center text-green-500">
          <AiOutlineMail />
          <a
            href="mailto:info@dialaserviceke.com"
            className="text-primary text-sm"
          >
            info@dialaserviceke.com
          </a>
        </p>

        <div className="flex gap-1 items-center justify-center text-green-500 bg-primary p-1 rounded-md cursor-pointer">
          <span className="bg-white p-1 text-xl rounded-full">
            <HiOutlineUserCircle />
          </span>
          <a className="text-white text-sm font-bold"> Request For Service </a>
        </div>
      </div>
      <div className={`flex-1 md:p-2 backgroundImage relative`}>
        <nav className="flex items-center justify-between px-4 md:px-10 bg-primary md:bg-transparent z-40 py-1 fixed w-screen md:w-full md:static">
          <div className="flex items-center gap-5">
            <img
              src={logo}
              alt="logo"
              className="h-12 md:h-16 border rounded-full border-primary shadow-md shadow-primary"
            />
            <p className="hidden md:blcok capitalize text-xl text-white font-bold text-shadow">Dial A Service</p>
          </div>
          <div onClick={() => setShowNavigation(true)} className="h-full w-6 flex flex-col gap-1 cursor-pointer md:hidden">
              <div className="bg-white h-1 w-full"></div>
              <div className="bg-white h-1 w-full"></div>
              <div className="bg-white h-1 w-full"></div>
          </div>
          <div className={`${showNavigation? "flex absolute" : "hidden"} top-0 left-0 text-black w-screen h-screen md:h-max md:flex flex-col bg-white md:bg-transparent md:static md:flex-row font-xl md:w-3/4 items-center justify-evenly md:text-white font-bold`}>
            <div onClick={() => setShowNavigation(false)} className="absolute top-10 right-10 h-8 w-6 flex flex-col gap-1 cursor-pointer md:hidden">
                <div className="bg-red-500 h-[2px] w-full rotate-45 absolute"></div>
                <div className="bg-red-500 h-[2px] w-full -rotate-45 absolute"></div>
            </div>

            <a href="/" onClick={() => setShowNavigation(false)} className="text-primary md:text-white">Home</a>
            <a href="/" onClick={() => setShowNavigation(false)} className="text-primary md:text-white">Our Services</a>
            <a href="/" onClick={() => setShowNavigation(false)} className="text-primary md:text-white">FAQ</a>
            <div className="md:hidden flex gap-1 items-center justify-center text-green-500 bg-primary p-1 rounded-md cursor-pointer">
              <span className="bg-white p-1 text-xl rounded-full">
                <HiOutlineUserCircle />
              </span>
              <a className="text-white text-sm font-bold"> Request For Service </a>
            </div>
          </div>
        </nav>
       
      </div>
    </div>
    <div className="p-2 text-sm mt-12 md:mt-0">
        <h1 className="text-primary font-bold text-center py-2">Simplifying Life for You</h1>
        <p className="text-sm">At <span className="text-primary">Dial a Service</span>, our core objective is to alleviate the hassles of finding qualified service providers for your household and office needs.
 We understand the everyday challenges you face, such as:</p>
        <ul className="text-sm list-disc px-5 py-2">
            <li className="px-1">Waking up to the unexpected absence of your house manager, leaving you in a bind, juggling both your professional commitments and household responsibilities.</li>
            <li className="px-1">House managers often heading to their rural homes, causing delays or even prolonged absences.</li>
            <li className="px-1">Synchronizing leave requests with your house manager's availability can be a logistical nightmare.</li>
        </ul>
        <p>Enter Dial a Service, an innovative application designed to empower you with the ability to effortlessly access services that traditionally involved time-consuming searches and vetting processes. With just a simple tap, we connect you with skilled professionals, eliminating the need for personal connections or worrying about the credentials of the service provider.</p>

        <h1 className="text-primary font-bold py-2">Our Approach</h1>
        <p>Dial a Service collaborates directly with workers and reputable companies, ensuring comprehensive training and thorough vetting before deployment. Our user-friendly app allows you to log in and request services from various domains without the need for referrals. We've got you covered when it comes to training and reliability.</p>

        <h1 className="text-primary font-bold py-2">Our Vision</h1>
        <p>Making Life Effortless</p>

        <h1 className="text-primary font-bold py-2">Our Mision</h1>
        <p>We are dedicated to providing cutting-edge technology that bridges potential employers with a pool of qualified, pre-trained professionals who are always ready to assist. This mission is realized through our mobile application, "Dial a Service," available on both Android and IOS platforms.</p>

        <h1 className="text-primary font-bold py-2">Our Values</h1>
        <ul className="text-sm list-disc px-5 py-2">
            <li className="px-1"><span className="text-primary">Professionalism:</span> We respond to the needs of our customers and partners with utmost efficiency and professionalism, ensuring a seamless experience.</li>
            <li className="px-1"><span className="text-primary">Quality Assurance:</span> We are committed to delivering services of the highest quality, adhering to rigorous standards.</li>
            <li className="px-1"><span className="text-primary">Social Responsibility:</span> We actively contribute to sustainable development, fostering a positive impact on our community and the environment.</li>
            <li className="px-1"><span className="text-primary">Accountability and Transparency:</span> We promote openness, constructive feedback, and responsibility in all our endeavors, ensuring trust and reliability in everything we do.</li>
        </ul>

        <p>Choose <span className="text-primary">Dial a Service</span> for a more convenient, reliable, and professional approach to meeting your home and office service needs. Experience the difference with Dial a Service.</p>

        <ol className="list-decimal px-5 py-2">
            <li className="text-primary font-bold">Our Range of Services:</li>
            <p>At <span className="text-primary">Dial a Service</span>, we are dedicated to making your life easier by offering a diverse array of services. While our list continues to grow, here are the services currently available:</p>

            <ul className="text-sm list-disc px-5 py-2">
                <li className="px-1">Nanny Services: We provide both day and night care nannies to ensure your child's well-being.</li>
                <li className="px-1">House Girls</li>
                <li className="px-1">Day Bags</li>
                <li className="px-1">Sitting (Live In)</li>
                <li className="px-1">Care Givers</li>
                <li className="px-1">Security Guards: Whether you need short-term or long-term security solutions, our trained guards are here to protect you.</li>
                <li className="px-1">Errand Services: Our reliable errand persons are at your service for deliveries, home shopping, and food delivery.</li>
                <li className="px-1">Professional Services: For your home maintenance needs, we have skilled electricians and plumbers on call.</li>
                <li className="px-1">Professional Cleaning Services: We offer thorough cleaning for homes and offices, maintaining a fresh and tidy environment.</li>
                <li className="px-1">Concierge Services: Count on us for reservations and expert event planning.</li>
                <li className="px-1">Shamba boys / Gardens</li>
            </ul>

            <p>And remember, we're constantly expanding our offerings to serve you better.</p>
            <li className="text-primary font-bold"> Operating Hours:</li>
            <p> Dial a Service operates 24/7, so you can access our services whenever you need them. Our user-friendly app is available around the clock for your convenience.</p>
            <li className="text-primary font-bold">Pricing:</li>
            <p>Our pricing varies depending on the service you require. Rest assured, the cost for each service will be clearly displayed before you confirm your booking.</p>
            <li className="text-primary font-bold">Nanny Training:</li>
            <p>Our nannies and house managers are well-trained in basic first aid and fire management, ensuring your family's safety.</p>
            <li className="text-primary font-bold">Verification Process:</li>
            <p>Your safety is our priority. To confirm the identity of our service providers, a unique code will be generated by our system and sent to both the service provider and you, the requester. Only allow entry to the person if the two codes match, ensuring you get the service you expect.</p>
            <li className="text-primary font-bold"> Provider Vetting:</li>
            <p>We take the security of our customers seriously. Our service providers undergo rigorous background checks. Additionally, we partner with reputable market leaders who also conduct thorough background checks on their service providers.</p>
        </ol>

        <p>At Dial a Service, we believe in delivering peace of mind along with our services. Trust us to provide you with the assistance you need, when you need it. Your satisfaction and safety are our top priorities.</p>

    </div>
   
    <div className="flex flex-col p-5">
      <h1 className="font-bold text-xl py-3 text-primary">It's Easier On The App</h1>
      <div className="flex-1 border border-primary rounded-md p-5 flex gap-5 items-center">
        <img src={qrcode} alt="qrcode" className="h-24 md:h-52" />
        <div>
          <p className="text-sm md:text-xl font-bold p-2">Download the Dial A Service App</p>
          <p className="text-primary font-bold p-2 flex items-center justify-between">scan to download <span className="text-black text-2xl font-bold"><BsArrowRight /></span></p>
        </div>
      </div>
    </div>
    <div className="text-white text-sm bg-black flex flex-wrap items-start justify-evenly p-5 pb-10 relative">
      <div className="flex flex-col">
        <h1 className="text-primary font-bold py-1">Social Media</h1>
        <div className="flex justify-center gap-2">
          <a href="https://x.com/DialAService01?t=x4Pc-_t4f54X0UQMeSkqrQ&s=09" target="_blank"><FaXTwitter /></a>
          <a href="https://www.facebook.com/dailaservice?mibextid=ZbWKwL" target="_blank"><BsFacebook /></a>
        </div>
      </div>
      <div className="">
        <h1 className="text-primary font-bold py-1">Company</h1>
        <div>
            <a className="block" href="/about">About Us</a>
            <a className="block" href="/termsandconditions">Terms and Conditions</a>
            <a className="block" href="/privacy">Privacy Policies</a>
          </div>
      </div>
      <div className="">
        <h1 className="text-primary font-bold py-1">Our Services</h1>
        <div>
          <p>Home Services</p>
          <p>Proffesional Services</p>
          <p>Convenience Services</p>
          <p>Events and special occasions</p>
        </div>
      </div>
      <div className="">
        <h1 className="text-primary font-bold py-1">Contact Us</h1>
        <div>
          <p>+254 786737737</p>
        </div>
      </div>

      <p className="absolute bottom-0 left-0 right-0 text-center m-auto">&copy; {new Date().getFullYear()} Zeaworld Ltd. All rights reserved.</p>
    </div>
  </section>
  )
}

export default AboutUs