import React, { useEffect, useState } from "react";
import logo from "../images/logo.jpg";
import cleaner from "../images/cleaning-worker-with-bucket-and-broom-vector.jpg";
import event from "../images/event.jpg";
import convenience from "../images/convenience.png";
import security from "../images/security.jpeg";
import qrcode from "../images/qrcode.png";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { HiOutlineUserCircle } from "react-icons/hi";
import { FaXTwitter } from "react-icons/fa6";
import { BsFacebook } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";
import { BsChevronDoubleRight, BsChevronDoubleLeft } from "react-icons/bs";

const LandingPage = () => {
  const [showNavigation, setShowNavigation] = useState(false);
  const [FAQs, setFAQs] = useState({});
  const [activeFaq, setActiveFaq] = useState({});

  useEffect(() => {
    getFAQs();
  }, []);

  const goToNextFaq = () => {
    let index = activeFaq.index;

    if (index == FAQs.length - 1) {
      index = 0;
    } else {
      index = index + 1;
    }

    console.log(index)
    let faq = FAQs[index];
    faq["index"] = index;

    setActiveFaq(faq);
  };

  const goToPreviousFaq = () => {
    let index = activeFaq.index;

    if (index == 0) {
      index = FAQs.length -1;
    } else {
      index = index - 1;
    }

    console.log(index)
    let faq = FAQs[index];
    faq["index"] = index;

    setActiveFaq(faq);
  };

  console.log(activeFaq);
  const getFAQs = async () => {
    const response = await fetch("https://api.dialaserviceke.com/api/faq");

    if (!response.ok) {
      return;
    }

    const result = await response.json();
    let faq = result.faqs[0];
    faq["index"] = 0;

    setActiveFaq(faq);
    setFAQs(result.faqs);
  };

  return (
    <section class="w-full">
      <div className="W-full h-screen flex flex-col">
        <div className="hidden md:flex gap-3 px-4 items-center justify-end py-1">
          <p className="flex gap items-center justify-center text-green-500">
            <BsTelephone />
            <span className="text-primary text-sm">+254786737737</span>
          </p>

          <p className="flex items-center justify-center text-green-500">
            <AiOutlineMail />
            <a
              href="mailto:info@dialaserviceke.com"
              className="text-primary text-sm"
            >
              info@dialaserviceke.com
            </a>
          </p>

          <div className="flex gap-1 items-center justify-center text-green-500 bg-primary p-1 rounded-md cursor-pointer">
            <span className="bg-white p-1 text-xl rounded-full">
              <HiOutlineUserCircle />
            </span>
            <a className="text-white text-sm font-bold"> Request For Service </a>
          </div>
        </div>
        <div className={`flex-1 md:p-2 backgroundImage relative`}>
          <nav className="flex items-center justify-between px-4 md:px-10 bg-primary md:bg-transparent z-40 py-1 fixed w-screen md:w-full md:static">
            <div className="flex items-center gap-5">
              <img
                src={logo}
                alt="logo"
                className="h-12 md:h-16 border rounded-full border-primary shadow-md shadow-primary"
              />
              <p className="hidden md:blcok capitalize text-xl text-white font-bold text-shadow">
                Dial A Service
              </p>
            </div>
            <div
              onClick={() => setShowNavigation(true)}
              className="h-full w-6 flex flex-col gap-1 cursor-pointer md:hidden"
            >
              <div className="bg-white h-1 w-full"></div>
              <div className="bg-white h-1 w-full"></div>
              <div className="bg-white h-1 w-full"></div>
            </div>
            <div
              className={`${
                showNavigation ? "flex absolute" : "hidden"
              } top-0 left-0 text-black w-screen h-screen md:h-max md:flex flex-col bg-white md:bg-transparent md:static md:flex-row font-xl md:w-3/4 items-center justify-evenly md:text-white font-bold`}
            >
              <div
                onClick={() => setShowNavigation(false)}
                className="absolute top-10 right-10 h-8 w-6 flex flex-col gap-1 cursor-pointer md:hidden"
              >
                <div className="bg-red-500 h-[2px] w-full rotate-45 absolute"></div>
                <div className="bg-red-500 h-[2px] w-full -rotate-45 absolute"></div>
              </div>

              <a
                href="#"
                onClick={() => setShowNavigation(false)}
                className="text-primary md:text-white"
              >
                Home
              </a>
              <a
                href="#ourservices"
                onClick={() => setShowNavigation(false)}
                className="text-primary md:text-white"
              >
                Our Services
              </a>
              <a
                href="/about"
                onClick={() => setShowNavigation(false)}
                className="text-primary md:text-white"
              >
                About Us
              </a>
              <a
                href="#faqs"
                onClick={() => setShowNavigation(false)}
                className="text-primary md:text-white"
              >
                FAQ
              </a>
              <div className="md:hidden flex gap-1 items-center justify-center text-green-500 bg-primary p-1 rounded-md cursor-pointer">
                <span className="bg-white p-1 text-xl rounded-full">
                  <HiOutlineUserCircle />
                </span>
                <a className="text-white text-sm font-bold"> Request For Service </a>
              </div>
            </div>
          </nav>
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 md:-translate-x-0 -translate-y-1/2 md:left-1/4 flex flex-col gap-5">
            <p className="font-bold text-2xl flex flex-col">
              <span className="text-white">Making</span>
              <span className="block mx-auto -translate-x-1/2">Life Easy.</span>
            </p>

            <p className="text-white text-xl w-[300px]">
              Dial a Service is a smart App that connects you to professional
              service Providers
            </p>
            <a href="/downloads/requester" className="text-primary cursor-pointer p-2 mx-auto md:mx-0 rounded-md bg-white shadow-primary font-bold shadow-md text-sm w-max">
              Get The App
            </a>
          </div>
        </div>
      </div>
      <div id="ourservices" className="min-h-screen flex flex-col">
        <h1 className="font-bold text-xl text-center py-3">Our Services</h1>
        <div className="flex-1 grid grid-cols-1 md:grid-cols-2 p-5 gap-y-5 items-center">
          <div className="relative shadow-md shadow-primary border border-primary md:w-[80%] p-3 rounded-md">
            <h1 className="text-center text-primary">Home Services</h1>
            <div className="flex items-center justify-between py-2">
              <div className="text-sm min-h-[140px]">
                <p>Home Managers</p>
                <p>Nannies</p>
                <p>House Girls</p>
                <p>Caregivers</p>
                <p>Shamba boys and Gardeners</p>
                <p>Day Burgs</p>
              </div>
              <img
                src={cleaner}
                className="rounded-full md:absolute right-0 top-1/2 md:-translate-y-1/2 h-20 md:h-32 md:translate-x-1/2 border border-primary md:rounded-md"
                alt="cleaner"
              />
            </div>
          </div>
          <div className="relative shadow-md shadow-primary border border-primary md:w-[80%] p-3 rounded-md">
            <h1 className="text-center text-primary">Proffesional Services</h1>
            <div className="flex items-center justify-between py-2">
              <div className="text-sm min-h-[140px]">
                <p>Electricians</p>
                <p>Plumbers</p>
                <p>Events Security</p>
                <p>Security Quards</p>
                <p>Event Services</p>
                <p>Concierge Services</p>
              </div>
              <img
                src={security}
                className="h-20 rounded-full md:absolute right-0 top-1/2 md:-translate-y-1/2 md:h-32 md:translate-x-1/2 border border-primary md:rounded-md"
                alt="cleaner"
              />
            </div>
          </div>
          <div className="relative shadow-md shadow-primary border border-primary md:w-[80%] p-3 rounded-md">
            <h1 className="text-center text-primary">Convenience Services</h1>
            <div className="flex items-center justify-between py-2">
              <div className="text-sm min-h-[140px]">
                <p>Errand Services</p>
                <p>Transportation Services</p>
                <p>Emergency Responses</p>
              </div>
              <img
                src={convenience}
                className="h-20 rounded-full md:absolute right-0 top-1/2 md:-translate-y-1/2 md:h-32 md:translate-x-1/2 border border-primary md:rounded-md"
                alt="cleaner"
              />
            </div>
          </div>
          <div className="relative shadow-md shadow-primary border border-primary md:w-[80%] p-3 rounded-md">
            <h1 className="text-center text-primary">
              Events and special occasions
            </h1>
            <div className="flex items-center justify-between py-2">
              <div className="text-sm min-h-[140px]">
                <p>Event Photography</p>
              </div>
              <img
                src={event}
                className="h-20 rounded-full md:absolute right-0 top-1/2 md:-translate-y-1/2 md:h-32 md:translate-x-1/2 border border-primary md:rounded-md"
                alt="cleaner"
              />
            </div>
          </div>
        </div>
      </div>
      {/* FAQs */}
      <div id="faqs" className="min-h-[300px] max-w-full flex flex-col">
        <h1 className="font-bold text-xl text-center py-3">FAQs</h1>
        <div className="flex-1 border border-primary rounded-md flex items-center">
          <div onClick={()=> goToPreviousFaq()} className="font-bold px-2 cursor-pointer">
            <BsChevronDoubleLeft />
          </div>
          <div className="flex-1 flex flex-col">
            {!Object.keys(activeFaq).length ? (
              ""
            ) : (
              <div className="mx-auto  flex flex-col">
                <p className="text-primary">
                  {" "}
                  <span>{activeFaq.index + 1}.</span>
                  {activeFaq.question}
                </p>
                <ul className="pl-6 list-disc">
                  <li className="capitalize  max-w-[80%]">{activeFaq.answer}</li>
                </ul>
              </div>
            )}
          </div>
          <div
            onClick={() => goToNextFaq()}
            className="font-bold px-2 cursor-pointer"
          >
            <BsChevronDoubleRight />
          </div>
        </div>
      </div>
      <div className="flex flex-col p-5">
        <h1 className="font-bold text-xl py-3 text-primary">
          It's Easier On The App
        </h1>
        <div className="flex-1 border border-primary rounded-md p-5 flex gap-5 items-center">
          <img src={qrcode} alt="qrcode" className="h-24 md:h-52" />
          <div>
            <p className="text-sm md:text-xl font-bold p-2">
              Download the Dial A Service App
            </p>
            <p className="text-primary font-bold p-2 flex items-center justify-between">
              scan to download{" "}
              <span className="text-black text-2xl font-bold">
                <BsArrowRight />
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="text-white text-sm bg-black flex flex-wrap items-start justify-evenly p-5 pb-10 relative">
        <div className="flex flex-col">
          <h1 className="text-primary font-bold py-1">Social Media</h1>
          <div className="flex justify-center gap-2">
            <a
              href="https://x.com/DialAService01?t=x4Pc-_t4f54X0UQMeSkqrQ&s=09"
              target="_blank"
            >
              <FaXTwitter />
            </a>
            <a
              href="https://www.facebook.com/dailaservice?mibextid=ZbWKwL"
              target="_blank"
            >
              <BsFacebook />
            </a>
          </div>
        </div>
        <div className="">
          <h1 className="text-primary font-bold py-1">Company</h1>
          <div>
            <a className="block" href="/about">About Us</a>
            <a className="block" href="/termsandconditions">Terms and Conditions</a>
            <a className="block" href="/privacy">Privacy Policies</a>
          </div>
        </div>
        <div className="">
          <h1 className="text-primary font-bold py-1">Our Services</h1>
          <div>
            <p>Home Services</p>
            <p>Proffesional Services</p>
            <p>Convenience Services</p>
            <p>Events and special occasions</p>
          </div>
        </div>
        <div className="">
          <h1 className="text-primary font-bold py-1">Contact Us</h1>
          <div>
            <p>+254 786737737</p>
          </div>
        </div>

        <p className="absolute bottom-0 left-0 right-0 text-center m-auto">
          &copy; {new Date().getFullYear()} Zeaworld Ltd. All rights reserved.
        </p>
      </div>
    </section>
  );
};

export default LandingPage;
